<template>
	<div id="body">
		<div id="banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAdemobanner.jpg">
		</div>
		<div id="demo_main">
			<div id="demo_main_header">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/QY.png" >OA功能模块介绍
			</div>
			<div id="demo_main_flex">
				<div id="demo_main_flex_box" class="G" @click="$router.push('/msgdta')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAmsg.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">信息管理</p>
						<p>	• 通讯 • 邮件 • 新闻 • 公告</p>
						<p>• 知识 • 文档 • 贴吧 • 问答</p>
					</div>
				</div>
				<div id="demo_main_flex_box"class='B' @click="$router.push('/Setinghelp')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAset.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">系统管理</p>
						<p>• 机构 • 岗位 • 角色 • 用户</p>
						<p>• 职级 • 权限 • 日志 • 参数</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="O" @click="$router.push('/target')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAmb.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">目标管理</p>
						<p>• 计划 • 任务 • 报告 • 日程</p>
						<p>• 分解 • 落实 • 追踪 • 检查</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class='B'  @click="$router.push('/administrative')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAxz.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">行政管理</p>
						<p>• 会议 • 车辆 • 资产 • 用品</p>
						<p>• 证照 • 印章 • 审批 • 监管</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="O"  @click="$router.push('/personnel')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OArs.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">人事管理</p>
						<p>• 档案 • 招聘 • 转正 • 考勤</p>
						<p>• 福利 • 培训 • 考核 • 薪酬</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="G" @click="$router.push('/finance')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAcw.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">财务管理</p>
						<p>• 差旅 • 费用 • 票据 • 资金</p>
						<p>• 收入 • 支出 • 审批 • 汇总</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="O" @click="$router.push('/clientrelations')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAkh.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">客户关系管理</p>
						<p>• 客户 • 商机 • 跟单 • 合同</p>
						<p>• 回款 • 开票 • 售后 • 统计</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="G" @click="$router.push('/PM')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAxm.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">项目管理</p>
						<p>• 需求 • 组织 • 任务 • 工时</p>
						<p>• 进度 • 成本 • 文档 • 讨论</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class='B' @click="$router.push('/IERP')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAjxc.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">进销存管理</p>
						<p>• 品种 • 销售 • 采购 • 库存</p>
						<p>• 订单 • 退货 • 台账 • 统计</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="G" @click="$router.push('/Mydesktop')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAmy.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">我的桌面</p>
						<p>• 待办 • 门户 • 聚合 • 量化</p>
						<p>• 状态 • 通知 • 提醒 • 新增</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class='B' @click="$router.push('/Myaffairs')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAsw.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">我的事务</p>
						<p>• 表单 • 流程 • 审批 • 发布</p>
						<p>• 待办 • 已办 • 过程 • 打印</p>
					</div>
				</div>
				<div id="demo_main_flex_box" class="O" @click="$router.push('/MORE')">
					<div id="demo_main_flex_box_left">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/OAgd.png" >
					</div>
					<div id="demo_main_flex_box_right">
						<p class="Tit">更多......</p>
						<p>• 公文 • 预算 • 物联 • 生产</p>
						<p>• 网站 • 微信 • 集成 • 定制</p>
					</div>
				</div>
			</div>
		</div>
		<div id="go">
			<p @click="ZCfun">立即注册体验<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Go.png"/></p>
			<p id="Pc" @click="$router.push('/store')">了解如何购买<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Go.png"/></p>
			<p>需要更多功能<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Go.png"/></p>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		methods:{
			ZCfun(){//注册
				// window.location.href = "http://rhdgjzc.ronghengzn.com/";
			}
		}
		}
</script>

<style scoped="scoped" lang="less">
	@media screen and (min-width: 720px) {
	
		//电脑
		#banner {
			width: 100%;
			height: 700px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	
	@media screen and (max-width: 720px) {
	
		//手机
		#banner {
			width: 100%;
			height: 200px;
	
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	@media screen and (min-width:720px){//电脑
		#demo_main{
			width: 80%;
			height: 900px;
			margin-left: 10%;
			margin-top: 5%;
			margin-bottom:3%;
			#demo_main_header{
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				img{
					width: 5%;
					margin-right: 1%;
				}
			}
			#demo_main_flex{
				width: 80%;
				height: 90%;
				margin-left: 10%;
				margin-top: 3%;
				display: flex;
				cursor: pointer;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				#demo_main_flex_box{
					width: 28%;
					height: 22%;
					display: flex;
					border: 1px solid #e2e1e1;
					#demo_main_flex_box_left{
						width: 20%;
						height: 100%;
						padding-top: 6%;
						text-align: center;
						img{
							width: 70%;
							max-height: 100%;
						}
					}
					#demo_main_flex_box_right{
						width: 80%;
						height: 100%;
						padding-top: 8%;
						// text-align: center;
						.Tit{
							font-size: 26px;
							font-weight: bold;
						}
					}
				}
				.G{
					.Tit{
						color: seagreen;
					}
				}
				.G:hover {
					border: 1px solid seagreen !important;
				}
				.B{
					.Tit{
						color: steelblue;
					}
				}
				.B:hover {
					border: 1px solid steelblue !important;
				}
				.O{
					.Tit{
						color: orange;
					}
				}
				.O:hover {
					border: 1px solid orange !important;
				}
			}
		}
	}
	@media screen and (max-width:720px){//手机
		#demo_main{
			width: 100%;
			// height: 900px;
			margin-top: 5%;
			margin-bottom:3%;
			#demo_main_header{
				text-align: center;
				font-size: 26px;
				font-weight: bold;
				img{
					width: 10%;
					margin-right: 1%;
				}
			}
			#demo_main_flex{
				width: 100%;
				height: 90%;
				margin-top: 3%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				#demo_main_flex_box{
					width: 90%;
					height: 22%;
					margin-bottom: 3%;
					display: flex;
					border: 1px solid #e2e1e1;
					#demo_main_flex_box_left{
						width: 20%;
						height: 100%;
						padding-top: 6%;
						text-align: center;
						img{
							width: 70%;
							max-height: 100%;
						}
					}
					#demo_main_flex_box_right{
						width: 80%;
						height: 100%;
						padding-top: 8%;
						// text-align: center;
						.Tit{
							font-size: 26px;
							font-weight: bold;
						}
					}
				}
				.G{
					.Tit{
						color: seagreen;
					}
				}
				.G:hover {
					border: 1px solid seagreen !important;
				}
				.B{
					.Tit{
						color: steelblue;
					}
				}
				.B:hover {
					border: 1px solid steelblue !important;
				}
				.O{
					.Tit{
						color: orange;
					}
				}
				.O:hover {
					border: 1px solid orange !important;
				}
			}
		}
	}
	@media screen and (min-width:720px) {//电脑
		#go{
			width: 60%;
			height: 100px;
			margin-left: 20%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			p{
				color: #fd3f31;
				font-size: 22px;
				height: 100%;
				line-height: 100px;
				cursor: pointer;
				img{
					height: 40%;
				}
			}
		}
	}
	@media screen and (max-width:720px) {//手机
		#go{
			width: 80%;
			margin-left: 10%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 20%;
			p{
				color: #fd3f31;
				font-size: 16px;
				width: 51%;
				height:50px;
				line-height: 100px;
				cursor: pointer;
				margin: 0;
				img{
					height: 40%;
				}
			}
			#Pc{
				display: none;
			}
		}
	}
</style>
